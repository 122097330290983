@import 'modern-normalize';

:root {
	--back: #f6f3ea;
	--base: #fff;
	--text: #004c6c;
	--large-text: 28px;
	--radius: 6px;
}
@media (prefers-color-scheme: dark) {
	:root:not([data-theme='white']) {
		--back: #004c6c;
		--base: #002b3e;
		--text: #fff;
	}
	nav img {
		filter: contrast(1000) invert();
	}
}

.toggle-allergens,
.dish-image {
	margin: 0;
	padding: 0;
	border: none;
	font: inherit;
	color: inherit;
	background: none;
	overflow: visible;
}

.container {
	margin: auto;
}

body {
	background: var(--back);
	color: var(--text);
	font: 16px/1.8em Optima, Corbel, sans-serif;
}
.content {
	padding-left: 5px;
	padding-right: 5px;
}
nav {
	margin: 20px auto;
}
main nav {
	position: sticky;
	top: 0;
	z-index: 100;
	background: var(--back);
}
nav .container {
	display: flex;
	align-items: center;
	padding: 5px 16px;
}

nav img {
	height: 50px;
	width: auto;
	margin-left: 0.4em;
}

main:not([hidden]) nav .nav {
	display: flex;
	margin-left: auto;
	animation: zoom 0.2s;
}

@keyframes zoom {
	from {
		transform: scale(0.5)
	}
}

.menu a,
nav .nav {
	font: inherit;
	text-decoration: none;
	padding: 10px 1em;
	color: var(--text);
	background: var(--base);
	border-radius: 5em;
}

.menu {
	display: flex;
	flex-direction: column;
	align-items: start;
	margin-bottom: 70px;
	padding: 10px;
}
.menu a,
.menu .toggle-allergens:after {
	text-decoration: none;
	color: inherit;
	font-size: var(--large-text);
	font-weight: normal;
	background: var(--base);
	padding: 0.3em;
	margin-bottom: 0.3em;
	box-shadow: 0 2px 19px rgba(119, 87, 0, 0.1);
	border-radius: 2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: fit-content;
}

.ball {
	display: flex;
	align-items: center;
}

.ball::before {
	--size: 1.4em;
	content: '';
	display: block;
	width: var(--size);
	height: var(--size);
	border-radius: 100%;
	background-color: var(--highlight, #f0eadc);
	flex-shrink: 0;
}
.pill-text {
	margin: 0 0.3em;
}

@media (max-width: 374px) {
	.menu a {
		margin-left: -1.5em;
	}
	:root {
		--large-text: 25px;
	}
}

h1 {
	font-size: var(--large-text);
	line-height: 1.8em;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	z-index: 50;
}
.sticky {
	position: sticky;
	z-index: 50;
	top: 59px;
}

h1.ball::before {
	--size: 0.5em;
}

.ball:nth-of-type(1),
.ball-container:nth-of-type(1) h1 {
	--highlight: #0080a9;
}

.ball:nth-of-type(2),
.ball-container:nth-of-type(2) h1 {
	--highlight: #0098b1;
}

.ball:nth-of-type(3),
.ball-container:nth-of-type(3) h1 {
	--highlight: #00b0ba;
}

.ball:nth-of-type(4),
.ball-container:nth-of-type(4) h1 {
	--highlight: #7cd6cb;
}

.ball:nth-of-type(5),
.ball-container:nth-of-type(5) h1 {
	--highlight: #fdfcdb;
}

.ball:nth-of-type(6),
.ball-container:nth-of-type(6) h1 {
	--highlight: #ffecc8;
}

.ball:nth-of-type(7),
.ball-container:nth-of-type(7) h1 {
	--highlight: #fff8f7;
}

.ball:nth-of-type(8),
.ball-container:nth-of-type(8) h1 {
	--highlight: #fef0ef;
}

.ball:nth-of-type(9),
.ball-container:nth-of-type(9) h1 {
	--highlight: #fee1dd;
}

.ball:nth-of-type(10),
.ball-container:nth-of-type(10) h1 {
	--highlight: #fdd1cd;
}

.ball:nth-of-type(11),
.ball-container:nth-of-type(11) h1 {
	--highlight: #dc7472;
}

.ball:nth-of-type(12),
.ball-container:nth-of-type(12) h1 {
	--highlight: #f6978b;
}

.ball:nth-of-type(13),
.ball-container:nth-of-type(13) h1 {
	--highlight: #fcc4a9;
}

.ball:nth-of-type(14),
.ball-container:nth-of-type(14) h1 {
	--highlight: #ffd9b4;
}

.ball:nth-of-type(15),
.ball-container:nth-of-type(15) h1 {
	--highlight: #f9a58c;
}

.ball:nth-of-type(16),
.ball-container:nth-of-type(16) h1 {
	--highlight: #ffe3c9;
}

h2 {
	font-size: 20px;
	font-weight: normal;
	margin-top: 0;
	margin-bottom: 0;
}

.section {
	position: relative;
	scroll-margin: 70px;
	margin: 20px 0 60px;
}

:root .center {
	margin-left: auto;
	margin-right: auto;
	max-width: 768px;
}
.bg {
	background: var(--back);
}
.dish {
	background: var(--base);
	box-shadow: 0 2px 19px rgba(119, 87, 0, 0.1);
	padding: 10px;
	margin-bottom: 0.8em;
	border-radius: var(--radius);
}

button + .dish {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.dish strong {
	white-space: nowrap;
}

.dish-info {
	flex-grow: 1;
}

.dish-image {
	display: block;
	width: 100%;
	border-top-left-radius: var(--radius);
	border-top-right-radius: var(--radius);
	margin-top: 1.5em;
}
.dish-image img {
	width: 100%;
	height: 80px;
	display: block;
	object-fit: cover;
}

p {
	margin-bottom: 0;
	margin-top: 0;
}

.allergeni {
	margin-top: 1.5em;
}
.dish p:last-child {
	margin-bottom: 0;
}
footer {
	padding: 10px;
	white-space: pre-line;
}

[href='#bevande'],
[href='#amariedistillati'] {
	margin-top: 1em;
}

.Tipo {
	font-style: italic;
}

body:not(.show-allergens) .allergeni {
	display: none;
}
.menu .toggle-allergens {
	margin-top: 50px;
	margin-left: 0.4em;
	font-size: calc(var(--large-text) * 0.8);
}
.menu .toggle-allergens:after {
	display: inline-block;
	vertical-align: middle;
	--size: 40px;
	content: 'no';
	font-family: sans-serif;
	font-size: 0.7em;
	line-height: calc(var(--size) * 0.7);
	width: var(--size);
	height: var(--size);
	margin-left: 0.5em;
}

.show-allergens .toggle-allergens:after {
	content: 'sì';
}


.stagger-container:not([hidden]) .staggered-anim {
	animation: slide-in 0.4s 0.45s both;
}

.staggered-anim:nth-of-type(1) {
	animation-delay: 0s !important;
}
.staggered-anim:nth-of-type(2) {
	animation-delay: 0.09s !important;
}
.staggered-anim:nth-of-type(3) {
	animation-delay: 0.17s !important;
}
.staggered-anim:nth-of-type(4) {
	animation-delay: 0.24s !important;
}
.staggered-anim:nth-of-type(5) {
	animation-delay: 0.30s !important;
}
.staggered-anim:nth-of-type(6) {
	animation-delay: 0.35s !important;
}
.staggered-anim:nth-of-type(7) {
	animation-delay: 0.39s !important;
}
.staggered-anim:nth-of-type(8) {
	animation-delay: 0.42s !important;
}
.staggered-anim:nth-of-type(9) {
	animation-delay: 0.44s !important;
}
@keyframes slide-in {
	from {
		transform: translateY(10px);
		opacity: 0;
	}
}

@media print {
	* {
		background: none !important;
		box-shadow: none !important;
		position: static !important;
		color: black !important;
	}
	body {
		font-size: 14px;
	}
	nav,
	.dish-image,
	.ball:before,
	.nav {
		display: none !important;
	}
	main {
		display: block;
	}
	.ball,
	.pill-text {
		margin-left: 0;
		margin-right: 0;
	}
	.section,
	.dish {
		margin-bottom: 0;
	}
	h1 {
		page-break-before: always;
	}
}

